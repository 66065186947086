
import * as $    from 'jquery'
global.$ = global.jQuery = $;
import dt        from 'datatables.net-dt'
import buttons   from "datatables.net-buttons"
import {library} from "@fortawesome/fontawesome-svg-core";
import {
    faArrowDown, faBell, faBuilding, faChevronUp, faCog, faEnvelope, faMailBulk, faQuestion, faRetweet,
    faSignOutAlt, faStickyNote, faTachometerAlt, faToolbox
}                from "@fortawesome/free-solid-svg-icons";

//("#sidebarToggle, #sidebarToggleTop").on("click",function(e){l("body").toggleClass("sidebar-toggled"));
$(document).ready(function(){
    (() => {
    
        $.fn.DataTable = dt
        
        library.add(faMailBulk, faChevronUp, faTachometerAlt, faCog, faArrowDown, faBuilding, faStickyNote, faRetweet,
            faToolbox, faSignOutAlt, faQuestion, faBell, faEnvelope)
        
        
        document.getElementById("sidebarToggle").addEventListener("click", () => {
            document.getElementById("page-top").classList.toggle("sidebar-toggled")
            // $(".sidebar").toggleClass("toggled");
            document.querySelector(".sidebar").classList.toggle("toggled")
        })
    })()
})

